import React from 'react';
import { motion } from 'framer-motion';

const OurPartners = () => {
  const partners = [
    { name: 'NERD DAO', link: 'https://github.com/NERDDAO', pfp: 'https://avatars.githubusercontent.com/u/71939006?s=200&v=4' },
    // Add more partners as needed
  ];

  return (
    <div className="our-partners">
      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        style={{
          textShadow: '0 0 10px #0055aa, 0 0 20px #0055aa, 0 0 30px #0055aa',
          fontSize: 'clamp(2.5rem, 6vw, 3.6rem)',
          marginBottom: '2rem',
          color: '#ffffff',
          textAlign: 'center',
        }}
      >
        Our Partners
      </motion.h1>
      <motion.section 
        className="partners-content snap-section"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.5 }}
      >
        <div className="partners-grid">
          {partners.map((partner, index) => (
            <motion.div
              key={partner.name}
              className="partner"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-50px" }}
              transition={{ duration: 0.5, delay: 0.1 * index }}
              whileHover={{ 
                scale: 1.03, 
                boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1), 0 0 30px rgba(0, 123, 255, 0.2), 0 0 40px rgba(0, 255, 128, 0.2)'
              }}
              style={{
                padding: '1rem',
                margin: '0.5rem',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '10px',
                backdropFilter: 'blur(5px)',
              }}
            >
              <img src={partner.pfp} alt={`${partner.name} profile`} className="partner-pfp" />
              <h3 style={{ fontSize: 'clamp(1rem, 2.5vw, 1.5rem)', marginBottom: '0.5rem', color: 'var(--primary)' }}>{partner.name}</h3>
              <a href={partner.link} target="_blank" rel="noopener noreferrer" style={{ fontSize: 'clamp(0.8rem, 2vw, 1.1rem)', color: 'var(--text)' }}>
                Visit Partner
              </a>
            </motion.div>
          ))}
        </div>
      </motion.section>
    </div>
  );
};

export default OurPartners;