import React from 'react';
import { motion } from 'framer-motion';

const Portfolio = () => {
  const portfolioItems = [
    { title: 'Ludus', description: 'Operating at the intersection of gaming and sports technology' },
    { title: 'AI Agents and Assistants', description: 'Automating software programs through artificial intelligence' },
    { title: 'Agritech and Aquaculture Marketplaces', description: 'Facilitating trade and promoting economic growth in agriculture and aquaculture' },
    { title: 'Construction Tokenization', description: 'Revolutionizing the construction industry through blockchain technology' },
    { title: 'Smart Financial Products', description: 'Exploring the intersection of fintech and decentralized finance' }
  ];

  return (
    <div className="portfolio">
      <section className="hero-section snap-section">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          style={{
            textShadow: '0 0 10px #0055aa, 0 0 20px #0055aa, 0 0 30px #0055aa',
            fontSize: 'clamp(2.5rem, 6vw, 3.6rem)',
            color: '#ffffff',
            textAlign: 'center',
          }}
        >
          Our Portfolio
        </motion.h1>
      </section>
      <section className="portfolio-content snap-section">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          style={{
            textShadow: '0 0 10px #0055aa, 0 0 20px #0055aa, 0 0 30px #0055aa',
            fontSize: 'clamp(1.5rem, 4vw, 2.5rem)',
            marginBottom: '2rem',
            color: '#ffffff',
            textAlign: 'center',
          }}
        >
          Innovative Investments Across Our Verticals
        </motion.h2>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.6 }}
          className="vertical-grid"
        >
          {portfolioItems.map((item, index) => (
            <motion.div
              key={item.title}
              className="vertical-item"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-50px" }}
              transition={{ duration: 0.5, delay: 0.1 * index }}
              whileHover={{ 
                scale: 1.03, 
                boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1), 0 0 30px rgba(0, 123, 255, 0.2), 0 0 40px rgba(0, 255, 128, 0.2)'
              }}
            >
              <h4>{item.title}</h4>
              <p>{item.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </section>
    </div>
  );
};

export default Portfolio;