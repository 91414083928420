import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Home from './components/Home';
import Portfolio from './components/Portfolio';
import Team from './components/Team';
import AboutUs from './components/AboutUs';
import OurPartners from './components/OurPartners';
import ParticleBackground from './components/ParticleBackground';
import { Canvas } from '@react-three/fiber';
import CyberpunkScene from './components/CyberpunkScene';
import './App.css';

function App() {
  const [activeTab, setActiveTab] = useState('Home');
  const [blurStrength, setBlurStrength] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
      const blurValue = Math.min((scrollPosition / maxScroll) * 5, 5);
      setBlurStrength(blurValue);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="App">
      <ParticleBackground style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0 }} />
      <Canvas style={{ position: 'fixed', top: 0, left: 0, zIndex: 0, pointerEvents: 'none' }}>
        <CyberpunkScene />
      </Canvas>
      <div 
        className="blur-background" 
        style={{ 
          backdropFilter: `blur(${blurStrength}px)`,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          pointerEvents: 'none'
        }}
      ></div>
      <Header activeTab={activeTab} setActiveTab={setActiveTab} />
      <main className="content">
        {activeTab === 'Home' && <Home />}
        {activeTab === 'Portfolio' && <Portfolio />}
        {activeTab === 'Team' && <Team />}
        {activeTab === 'About Us' && <AboutUs />}
        {activeTab === 'Our Partners' && <OurPartners />}
      </main>
    </div>
  );
}

export default App;