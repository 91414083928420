import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Header = ({ activeTab, setActiveTab }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const tabs = ['Home', 'Portfolio', 'Team', 'About Us', 'Our Partners'];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <motion.header
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: 'spring', stiffness: 100 }}
      style={{ 
        padding: '1rem 1.5rem', 
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(10px)'
      }}
    >
      <div className="header-content">
        <h1 style={{ 
          fontSize: '2.5em', 
          color: '#1a1a1a', 
          margin: 0, 
          textShadow: '0 0 10px rgba(128, 128, 128, 0.7), 0 0 20px rgba(128, 128, 128, 0.5), 0 0 30px rgba(128, 128, 128, 0.3)'
        }}>Geek Capital</h1>
        <button onClick={toggleMenu} className="menu-toggle">
          {menuOpen ? 'Close' : 'Menu'}
        </button>
        <nav style={{ display: menuOpen ? 'flex' : 'none' }}>
          {tabs.map((tab) => (
            <motion.button
              key={tab}
              className={activeTab === tab ? 'active' : ''}
              onClick={() => {
                setActiveTab(tab);
                setMenuOpen(false);
              }}
              whileHover={{ scale: 1.05, boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)' }}
              whileTap={{ scale: 0.95 }}
              style={{
                background: `linear-gradient(135deg, #007BFF, #00A1FF)`,
                color: '#fff',
                border: 'none',
                padding: '0.4rem 0.8rem',
                borderRadius: '10px',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s ease',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                margin: '0.3rem',
              }}
            >
              {tab}
            </motion.button>
          ))}
        </nav>
      </div>
    </motion.header>
  );
};

export default Header;