import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

const CyberpunkScene = () => {
  const torusKnotRef = useRef();

  useFrame((state, delta) => {
    if (torusKnotRef.current) {
      torusKnotRef.current.rotation.x += delta * 0.2;
      torusKnotRef.current.rotation.y += delta * 0.2;
    }
  });

  return (
    <>
      <ambientLight intensity={0.2} />
      <pointLight position={[10, 10, 10]} intensity={0.5} />
      <mesh ref={torusKnotRef} position={[0, 0, -10]}>
        <torusKnotGeometry args={[3, 0.5, 100, 16]} />
        <meshStandardMaterial color="#00ffff" wireframe opacity={0.3} transparent />
      </mesh>
    </>
  );
};

export default CyberpunkScene;