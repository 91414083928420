import React from 'react';
import { motion } from 'framer-motion';

const Home = () => {
  const verticals = [
    { title: 'Agritech', description: 'Advancing sustainable farming with innovative agricultural technologies' },
    { title: 'Aquaculture Innovation', description: 'Pioneering in seafood production with sustainable and efficient aquaculture practices' },
    { title: 'Artificial Intelligence', description: 'Developing cutting-edge solutions that leverage AI to solve complex problems and enhance human capabilities' },
    { title: 'Construction Tech', description: 'Revolutionizing building techniques to improve efficiency, sustainability, and safety in construction' },
    { title: 'Fintech and DeFi', description: 'Reshaping financial services through decentralized finance and innovative financial technologies' },
    { title: 'Sports and Gaming Tech', description: 'Enhancing performance and experiences in sports and gaming through advanced technologies' },
  ];

  return (
    <motion.div className="home">
      <section className="hero-section snap-section">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          style={{
            textShadow: '0 0 10px #0055aa, 0 0 20px #0055aa, 0 0 30px #0055aa',
            fontSize: 'clamp(1.5rem, 4vw, 2.5rem)',
            marginBottom: '1rem',
            color: '#ffffff',
          }}
        >
          Where Geeks Meet Capital
        </motion.h1>
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          style={{
            textShadow: '0 0 10px #0055aa, 0 0 20px #0055aa, 0 0 30px #0055aa',
            fontSize: 'clamp(1.8rem, 5vw, 4rem)',
            lineHeight: '1.2',
            color: '#ffffff',
          }}
        >
          Shaping the Future of Technology
        </motion.h2>
      </section>
      <section className="mission-section snap-section">
        <div className="neon-text-container">
          <motion.p
            className="neon-text"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            style={{
              fontSize: 'clamp(1.1rem, 2.7vw, 2rem)',
              lineHeight: '1.6',
              maxWidth: '95%',
              textAlign: 'center',
              margin: '0 auto',
              padding: '2.5rem',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              borderRadius: '10px',
              backdropFilter: 'blur(5px)',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              wordBreak: 'keep-all',
              overflowWrap: 'normal',
              whiteSpace: 'normal',
            }}
          >
            We aim to leverage new technologies and create impactful businesses that revolutionize industries and improve lives globally.
          </motion.p>
        </div>
      </section>
      <motion.section 
        className="verticals-section snap-section"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.5 }}
        style={{ padding: '2rem 5%' }}
      >
        <h3 style={{ fontSize: 'clamp(1.3rem, 3.5vw, 2rem)', marginBottom: '1.5rem', textAlign: 'center' }}>Our Investment Verticals</h3>
        <div className="vertical-grid">
          {verticals.map((vertical, index) => (
            <motion.div
              key={vertical.title}
              className="vertical-item"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-50px" }}
              transition={{ duration: 0.5, delay: 0.1 * index }}
              whileHover={{ 
                scale: 1.03, 
                boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1), 0 0 30px rgba(0, 123, 255, 0.2), 0 0 40px rgba(0, 255, 128, 0.2)'
              }}
              style={{
                padding: '1rem',
                margin: '0.5rem',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '10px',
                backdropFilter: 'blur(5px)',
              }}
            >
              <h4 style={{ fontSize: 'clamp(1rem, 2.5vw, 1.5rem)', marginBottom: '0.5rem' }}>{vertical.title}</h4>
              <p style={{ fontSize: 'clamp(0.8rem, 2vw, 1.1rem)' }}>{vertical.description}</p>
            </motion.div>
          ))}
        </div>
      </motion.section>
    </motion.div>
  );
};

export default Home;