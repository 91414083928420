import React from 'react';
import { motion } from 'framer-motion';
import { FaTwitter, FaLinkedin } from 'react-icons/fa';

const Team = () => {
  const teamMembers = [
    { 
      name: 'Jose F. Vera D-V', 
      role: 'CEO', 
      pfp: '/jose-img.jpg', 
      twitter: '@josefvd15', 
      linkedin: 'https://www.linkedin.com/in/jose-f-vera-dalla-valle-037882180/' 
    },
    // Add more team members as needed
  ];

  return (
    <div className="team">
      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        style={{
          textShadow: '0 0 10px #0055aa, 0 0 20px #0055aa, 0 0 30px #0055aa',
          fontSize: 'clamp(2.5rem, 6vw, 3.6rem)',
          marginBottom: '2rem',
          color: '#ffffff',
          textAlign: 'center',
        }}
      >
        Meet Our Team
      </motion.h1>
      <motion.section 
        className="team-members snap-section"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.5 }}
      >
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <motion.div
              key={member.name}
              className="team-member"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-50px" }}
              transition={{ duration: 0.5, delay: 0.1 * index }}
              whileHover={{ 
                scale: 1.03, 
                boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1), 0 0 30px rgba(0, 123, 255, 0.2), 0 0 40px rgba(0, 255, 128, 0.2)'
              }}
              style={{
                padding: '1rem',
                margin: '0.5rem',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '10px',
                backdropFilter: 'blur(5px)',
              }}
            >
              <img src={member.pfp} alt={`${member.name} profile`} className="team-member-pfp" />
              <h3 style={{ fontSize: 'clamp(1rem, 2.5vw, 1.5rem)', marginBottom: '0.5rem', color: 'var(--primary)' }}>{member.name}</h3>
              <p style={{ fontSize: 'clamp(0.8rem, 2vw, 1.1rem)', color: 'var(--text)' }}>{member.role}</p>
              <div className="social-links">
                <a href={`https://twitter.com/${member.twitter.slice(1)}`} target="_blank" rel="noopener noreferrer" style={{ marginRight: '0.5rem' }}>
                  <FaTwitter size={24} color="var(--primary)" />
                </a>
                <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                  <FaLinkedin size={24} color="var(--primary)" />
                </a>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.section>
    </div>
  );
};

export default Team;