import React from 'react';
import { motion } from 'framer-motion';
import { FaHandsHelping, FaLightbulb, FaRocket, FaGlobe } from 'react-icons/fa';

const AboutUs = () => {
  const aboutSections = [
    {
      icon: <FaHandsHelping />,
      title: "Our Mission",
      content: "Geek Capital is dedicated to powering innovation across industries in North and South America. We combine deep technical expertise with a passion for transformative ideas."
    },
    {
      icon: <FaLightbulb />,
      title: "Our Vision",
      content: "We envision a future where technology enhances every aspect of life, creating sustainable solutions and driving economic growth."
    },
    {
      icon: <FaRocket />,
      title: "Our Approach",
      content: "We take a hands-on approach, providing not just capital, but also strategic guidance, industry connections, and operational support to our portfolio companies."
    },
    {
      icon: <FaGlobe />,
      title: "Our Impact",
      content: "By investing in cutting-edge technologies and visionary entrepreneurs, we aim to create lasting positive impact on industries, communities, and the global economy."
    }
  ];

  return (
    <div className="about-us">
      <section className="hero-section snap-section">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          style={{
            textShadow: '0 0 10px #0055aa, 0 0 20px #0055aa, 0 0 30px #0055aa',
            fontSize: 'clamp(2.5rem, 6vw, 3.6rem)',
            color: '#ffffff',
            textAlign: 'center',
          }}
        >
          About Geek Capital
        </motion.h1>
      </section>
      <section className="about-content snap-section">
        <div className="vertical-grid">
          {aboutSections.map((section, index) => (
            <motion.div
              key={section.title}
              className="vertical-item"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-50px" }}
              transition={{ duration: 0.5, delay: 0.1 * index }}
              whileHover={{ 
                scale: 1.03, 
                boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1), 0 0 30px rgba(0, 123, 255, 0.2), 0 0 40px rgba(0, 255, 128, 0.2)'
              }}
            >
              <div className="icon">{section.icon}</div>
              <h4>{section.title}</h4>
              <p>{section.content}</p>
            </motion.div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default AboutUs;